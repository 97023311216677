import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { usePaymentModalContext } from '@frontend/payments-collection-flow';
import { CreateInvoiceForCollection } from '@frontend/payments-invoice-create';
import { PersonInvoiceModalSteps, usePersonInvoicePaymentModal } from '../use-person-invoice-payment-modal';

export type CreateInvoiceProps = { person: Person };

export const CreateInvoice = ({ person }: CreateInvoiceProps) => {
  const { goToStep: goToInvoiceStep } = usePersonInvoicePaymentModal();
  const { onInvoiceCreated } = usePaymentModalContext() ?? {};
  return (
    <CreateInvoiceForCollection
      person={person}
      onInvoiceCreated={onInvoiceCreated}
      onClose={() => goToInvoiceStep(PersonInvoiceModalSteps.SelectInvoice)}
    />
  );
};
