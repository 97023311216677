import { useEffect, useMemo, useState } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useQueryClient } from 'react-query';
import { convertInvoiceV3toInvoiceModel, InvoiceModel } from '@frontend/api-invoices';
import { PersonsV3, PersonTypes } from '@frontend/api-person';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useGetInvoiceDetails } from '@frontend/payment-request-details';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { useQueryPaginatedInvoices } from '@frontend/payments-invoice-controller';
import {
  CreateInvoiceForm,
  DropZoneOverlay,
  shouldShowModal,
  ThresholdModals,
  useCreateInvoiceForm,
  useCreatePaymentRequest,
} from '@frontend/person-invoice-create';
import { getPersonActiveInvoiceKey } from '@frontend/person-invoice-list';
import { pendo } from '@frontend/tracking';
import { Modal, useModalControl, useModalLoadingState, useAlert } from '@frontend/design-system';

type PersonProps = {
  selectedPerson?: Person;
  onSelectInvoice: (invoice: InvoiceModel) => void;
  onClose?: () => void;
};

const convertToCents = (amount: number) => {
  return Math.round(amount * 100);
};

export const useCreateInvoiceModalContentsProps = ({
  selectedPerson: selectedPersonParam,
  onSelectInvoice,
  onClose,
}: PersonProps): CreateInvoiceModalContentsProps => {
  const selectedPerson = useMemo(
    () => selectedPersonParam && PersonsV3.PersonHelpers.convertPersonV3ToPerson(selectedPersonParam),
    [selectedPersonParam]
  );

  const createInvoiceFormProps = useCreateInvoiceForm({ selectedPerson });
  const { resetFields, selectedPerson: selectedPersonOnForm } = createInvoiceFormProps;
  const [newInvoice, setNewInvoice] = useState<InvoiceModel>();

  const { loading } = useGetInvoiceDetails({
    invoiceId: newInvoice?.id || '',
    personId: selectedPersonOnForm?.PersonID,
    onSuccess: (newInvoice: InvoiceModel) => onCreateComplete(newInvoice),
  });

  const onCreateComplete = (newInvoice: InvoiceModel) => {
    onSelectInvoice(newInvoice);
    resetFields(false);
  };

  const handleOnClose = () => {
    resetFields(false);
    onClose?.();
    return true;
  };

  return {
    createInvoiceFormProps,
    asyncModalBodyProps: {
      onClose: handleOnClose,
      setInvoice: setNewInvoice,
      selectedPerson: selectedPersonOnForm,
      invoiceLoading: loading,
    },
  };
};

type CreateInvoiceModalContentsProps = {
  createInvoiceFormProps: ReturnType<typeof useCreateInvoiceForm>;
  asyncModalBodyProps: AsyncModalProps;
  onCreated?: (invoice: InvoiceModel) => void;
  hideHeader?: boolean;
};

interface AsyncModalProps {
  onClose: () => void;
  onBack?: () => void;
  setInvoice: (invoice: InvoiceModel) => void;
  selectedPerson: PersonTypes.Person | null | undefined;
  invoiceLoading: boolean;
  onCreated?: (invoice: InvoiceModel) => void;
  hideHeader?: boolean;
}

export const CreateInvoiceModalContents = ({
  onCreated,
  hideHeader,
  asyncModalBodyProps,
  createInvoiceFormProps,
}: CreateInvoiceModalContentsProps) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { setLoading } = useModalLoadingState();

  const {
    createFormProps,
    attachment,
    setSelectedPerson,
    selectedPerson: V2SelectedPerson,
    skipAttachmentAuth,
    dropZoneProps,
  } = createInvoiceFormProps;
  const {
    newRequestUI,
    form: { values, isComplete },
  } = createFormProps;
  const { onClose, onBack, setInvoice, selectedPerson, invoiceLoading } = asyncModalBodyProps;

  // only used to clear queries
  const { locationIds } = useMultiQueryUtils();
  const { invoicesQueryKey } = useQueryPaginatedInvoices();
  const { createPaymentRequest, creatingPaymentRequest } = useCreatePaymentRequest({
    locationId: values.locationId,
  });

  const { modalProps: thresholdModalProps, triggerProps: thresholdTriggerProps } = useModalControl();

  const handleCreateInvoice = async () => {
    setLoading(true);
    try {
      const createInvoice = convertInvoiceV3toInvoiceModel(
        await createPaymentRequest({
          personId: values.personId!,
          amount: convertToCents(Number(values.amount)),
          attachment,
          mobileNumber: V2SelectedPerson?.MobilePhone,
          memo: values.memo,
          providerName: values.provider === 'none' ? undefined : values.provider,
          skipAttachmentAuth,
        }),
        values.locationId
      );
      queryClient.invalidateQueries(invoicesQueryKey);
      queryClient.invalidateQueries(getPersonActiveInvoiceKey(locationIds, values.personId));
      await setTimeout(() => setInvoice(createInvoice), 2000);
      onCreated?.(createInvoice);
      pendo.track('payments-create-invoice', {
        date: getTodaysDate('MM/DD/YYYY'),
        amount: Number(values.amount),
      });
    } catch (err) {
      alerts.error('Invoice could not be created. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!selectedPerson) {
      setSelectedPerson(selectedPerson);
    }
    return () => {
      setSelectedPerson(null);
    };
  }, [selectedPerson]);

  useEffect(() => {
    setLoading(invoiceLoading);
  }, [invoiceLoading]);

  return (
    <>
      {!hideHeader && (
        <Modal.Header onClose={onClose}>
          {newRequestUI ? t('Create Payment Request') : t('Create Invoice')}
        </Modal.Header>
      )}
      <Modal.Body>
        <DropZoneOverlay {...dropZoneProps}>
          <CreateInvoiceForm {...createFormProps} />
        </DropZoneOverlay>
      </Modal.Body>
      <Modal.Footer
        primary={{
          iconName: newRequestUI ? 'pay-small' : undefined,
          disabled: !isComplete || creatingPaymentRequest,
          label: newRequestUI ? t('Collect Payment') : t('Create'),
          onClick: shouldShowModal(values.amount) ? thresholdTriggerProps.onClick : handleCreateInvoice,
        }}
        secondary={{
          label: t('Cancel'),
          onClick: onClose,
        }}
        tertiary={{
          label: !!onBack ? t('Back') : undefined,
          onClick: onBack,
        }}
      />
      <ThresholdModals {...thresholdModalProps} amount={values.amount} onConfirm={handleCreateInvoice} />
    </>
  );
};
