import { getInvoice, InvoiceModel } from '@frontend/api-invoices';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useScopedQuery } from '@frontend/scope';

interface GetInvoiceDetailsProps {
  invoiceId: string | null;
  personId?: string;
  onSuccess?: (invoice: InvoiceModel) => void;
}

export const useGetInvoiceDetails = ({ invoiceId, personId, onSuccess }: GetInvoiceDetailsProps) => {
  const { paymentsUrl } = useMerchant();
  const { getMultiQueryKey, locationIds } = useMultiQueryUtils();

  const {
    data: invoice,
    isLoading,
    isFetching,
  } = useScopedQuery({
    enabled: !!paymentsUrl && !!invoiceId && !!personId,
    queryKey: getMultiQueryKey(['messaging-invoice-details', personId, invoiceId, locationIds]),
    queryFn: () => getInvoice(paymentsUrl || '', invoiceId || '', locationIds),
    onSuccess: (invoice) => onSuccess?.(invoice),
  });

  return { invoice, loading: isLoading || isFetching };
};
