import { CREATE_INVOICE_STEP } from '@frontend/payments-invoice-create';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';

export const PersonInvoiceModalSteps = {
  SelectInvoice: 'select-invoice',
  PreviewInvoice: 'preview-invoice',
  CreateInvoice: CREATE_INVOICE_STEP,
} as const;

export type PersonInvoiceModalStep = (typeof PersonInvoiceModalSteps)[keyof typeof PersonInvoiceModalSteps];

export const usePersonInvoicePaymentModal = () => {
  const { activeStep, goToStep: goToStepInContext } = useMultiStepModal();
  const goToStep = (step: PersonInvoiceModalStep) => goToStepInContext?.(step);

  return { activeStep, goToStep };
};
