import { useEffect } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { MultiStepModal } from '@frontend/payments-multistep-modal';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { Text } from '@frontend/design-system';
import { isPaidInvoice } from '../utils';
import { CardOnFile } from './card-on-file-payment';
import { collectPaymentModalStepList, CollectPaymentModalSteps, useCollectPaymentModal } from './hooks';
import { ManualCardEntry } from './manual-card-payment';
import { PaymentFlowList } from './payment-flow-list';
import {
  CollectionStepReaderSelection,
  CollectionStepReaderPayment,
  SelectedReaderProvider,
  devLogger,
} from './reader-payment';
import { ShareInEmail } from './share-in-email';
import { ShareInMessage } from './share-in-message';

const isInsideACollectionFlow = (activeStep: string | undefined) =>
  !!activeStep &&
  activeStep !== CollectPaymentModalSteps.CollectReaderPayment &&
  activeStep !== CollectPaymentModalSteps.PaymentFlowList &&
  collectPaymentModalStepList.includes(activeStep);

export const CollectPaymentModalBody = () => {
  const { t } = useTranslation('payments');
  const { invoice } = useSelectedInvoice();
  const { stripeLocationId } = useMerchant({ locationId: invoice?.locationId });
  const { locationId } = useMultiQueryUtils();
  const { aggregateValue: enableACHOnFile } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ACHOnFile,
    locationIds: [invoice?.locationId ?? ''],
  });
  const { aggregateValue: newCollectUI } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.NewCollectionRequestModal,
    locationIds: [locationId],
  });
  const { activeStep, goBack } = useCollectPaymentModal();

  useEffect(() => {
    if (isInsideACollectionFlow(activeStep) && isPaidInvoice(invoice)) {
      goBack(CollectPaymentModalSteps.PaymentFlowList);
    }
  }, [activeStep, invoice]);

  return (
    <>
      <MultiStepModal.Step
        id={CollectPaymentModalSteps.PaymentFlowList}
        title={newCollectUI ? t('Take Payment') : t('Collect Payment')}
        blueHeader={newCollectUI}
      >
        <PaymentFlowList />
      </MultiStepModal.Step>
      {stripeLocationId ? (
        <TerminalStepBody t={t} locationId={invoice?.locationId || ''} />
      ) : (
        <MultiStepModal.Step id={CollectPaymentModalSteps.ReaderSelection} title={t('Pay at Terminal')}>
          <Text>{t('No stripe location')}</Text>
        </MultiStepModal.Step>
      )}
      <MultiStepModal.Step id={CollectPaymentModalSteps.ShareInMessage} title={t('Text to Pay')} hideModalHeader>
        <ShareInMessage />
      </MultiStepModal.Step>
      <MultiStepModal.Step id={CollectPaymentModalSteps.ShareInEmail} title={t('Share in Email')} hideModalHeader>
        <ShareInEmail />
      </MultiStepModal.Step>
      <MultiStepModal.Step
        id={CollectPaymentModalSteps.CardOnFile}
        disableCloseOnOverlayClick
        disableCloseOnEscape
        title={enableACHOnFile ? t('Card/ACH on File') : t('Card on File')}
        hideModalHeader
      >
        <StripeElementsWrapper type={PaymentsFlowType.CardOnFilePayment}>
          <CardOnFile />
        </StripeElementsWrapper>
      </MultiStepModal.Step>
      <MultiStepModal.Step
        id={CollectPaymentModalSteps.ManualCardEntry}
        title={t('Manual Card Entry')}
        disableCloseOnEscape
        disableCloseOnOverlayClick
        hideBackButton
      >
        <StripeElementsWrapper type={PaymentsFlowType.ManualCardEntry}>
          <ManualCardEntry />
        </StripeElementsWrapper>
      </MultiStepModal.Step>
      <MultiStepModal.Step id={CollectPaymentModalSteps.Sunbit} title={t('Sunbit')} hideModalHeader>
        <ShareInMessage isBnplRequest />
      </MultiStepModal.Step>
    </>
  );
};

type TerminalStepBodyProps = {
  t: ReturnType<typeof useTranslation>['t'];
  locationId: string;
};

/**
 * Component allows us to switch out the terminal implementation
 */
const TerminalStepBody = ({ t }: TerminalStepBodyProps) => {
  devLogger.log('Using the new terminal client');

  return (
    <SelectedReaderProvider>
      <MultiStepModal.Step id={CollectPaymentModalSteps.ReaderSelection} title={t('Pay at Terminal')} hideBackButton>
        <CollectionStepReaderSelection />
      </MultiStepModal.Step>
      <MultiStepModal.Step
        id={CollectPaymentModalSteps.CollectReaderPayment}
        title={t('Pay at Terminal')}
        disableCloseOnOverlayClick
        disableCloseOnEscape
        hideBackButton
      >
        <CollectionStepReaderPayment />
      </MultiStepModal.Step>
    </SelectedReaderProvider>
  );
};
